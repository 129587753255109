@media only screen and (min-width: 0px) and (max-width: 1049px) {
  .sidebar {
    width: 100%;
    background-color: #f9f9f9;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    flex-wrap: wrap;

    position: absolute;

    top: 0;
    z-index: 9999999;
  }

  .sidebar_top {
    width: 100%;

    overflow-x: scroll;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    flex-wrap: nowrap;

    gap: 12px;
    padding-left: 24px;
  }

  .sidebar_top_navpoint {
    height: 40px;

    background-color: #f3f3f3;
    border-radius: 8px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding-left: 12px;
    padding-right: 12px;

    cursor: pointer;

    width: 50%;
  }

  .sidebar_top_navpoint_active {
    height: 40px;

    background-color: #ffeedb;
    border-radius: 8px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding-left: 12px;
    padding-right: 12px;

    cursor: pointer;
    width: 50%;
  }

  .sidebar_top_navpoint:hover {
    background-color: #dadada;
  }
}

@media only screen and (min-width: 1050px) {
  .sidebar {
    width: 180px;
    height: 100%;
    background-color: #f9f9f9;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .sidebar_top {
    width: 100%;
    margin-top: 30px;
  }

  .sidebar_top_navpoint {
    width: 100%;
    height: 40px;
    width: calc(100% - 24px);
    margin-left: 12px;

    background-color: #f3f3f3;
    margin-top: 12px;
    border-radius: 8px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding-left: 12px;

    cursor: pointer;
  }

  .sidebar_top_navpoint_active {
    width: 100%;
    height: 40px;
    width: calc(100% - 24px);
    margin-left: 12px;

    background-color: #ffeedb;
    margin-top: 12px;
    border-radius: 8px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding-left: 12px;

    cursor: pointer;
  }

  .sidebar_top_navpoint:hover {
    background-color: #dadada;
  }
}
