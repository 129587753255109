.camera_div {
}

.camera {
  text-align: center;
  display: block;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
}

.camera_off {
  width: 0px;
  height: 0px;
}

.camera-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.camera-video {
  transform: scaleX(-1);
  height: 100%;
  width: 100vw;
  object-fit: cover;
}

.camera-video_off {
  width: 0px;
  height: 0px;
}

.camera-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.camera-container canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.camera_div_bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin-top: -100px;
  z-index: 9999;
}

.camera_div_bottom_takePhoto {
  width: 60px;
  height: 60px;
  padding-left: 6px;
  padding-top: 6px;
  background-color: white;
  border-radius: 99999px;
  cursor: pointer;
  z-index: 9999;
}

.camera_div_bottom_takePhoto:hover {
  background-color: grey;
}

.camera_div_bottom_takePhoto_inner {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background-color: white;
  border-radius: 99999px;
  border: 2px solid #000000;
}

.camera_div_bottom_takePhoto_inner:hover {
  background-color: grey;
}

.result {
  display: none;
  position: relative;
  width: 100%;
  height: 300px; /* Adjust the height as needed */
}

.result.hasPhoto {
  display: block;
}

.blackbox {
  margin-top: -40px;
  margin-left: -10px;
  width: 50px;
  height: 50px;
  z-index: 9999;
  background-color: black;
}
